
import { mapActions } from "pinia";
import { useSiteStore } from "~/store/site";

export default {
  props: {
    correctPlanName: '',
    daysRemaining: '',
  },
  methods: {
    ...mapActions(useSiteStore, ["reAuthSiteShop"]),
  },
}
