
import { mapState } from 'pinia';
import { useRelayPlatformStore } from '~/store/relayPlatform'
export default {
  data() {
    return {}
  },
  computed: {
    ...mapState(useRelayPlatformStore, ['relayPlatformStaticPagesUrl', 'relayPlatformStaticPagesBannerUrl']
    ),
  },
  mounted() {
    window.addEventListener('message', this.handleRedirect)
  },
  beforeDestroy() {
    window.removeEventListener('message', this.handleRedirect);
  },
  methods: {
    handleRedirect(event){
      if(event.data.type == 'RelayPlatformBannerButton') {
        this.$router.push('/relay-persona');
      }
    },
  }
}
