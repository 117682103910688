import { render, staticRenderFns } from "./index.vue?vue&type=template&id=15157f09&scoped=true&lang=pug"
import script from "./index.vue?vue&type=script&lang=js"
export * from "./index.vue?vue&type=script&lang=js"
import style0 from "./index.vue?vue&type=style&index=0&id=15157f09&prod&lang=postcss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "15157f09",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {DrawerButton: require('/opt/build/repo/components/Layout/Drawer/DrawerButton.vue').default,Tag: require('/opt/build/repo/components/DataDisplay/Tag.vue').default,PlanChangeModal: require('/opt/build/repo/components/business/PlanChangeModal.vue').default,Button: require('/opt/build/repo/components/DataInput/Button.vue').default,SmartDialog: require('/opt/build/repo/components/Layout/SmartDialog.vue').default,BinarySwitch: require('/opt/build/repo/components/DataInput/BinarySwitch.vue').default,PopResponsivePreview: require('/opt/build/repo/components/business/PopResponsivePreview.vue').default})
