
import BasicLayout from '~/layouts/basic'
import { mapState, mapWritableState } from 'pinia'
import { useUiStore } from '~/store/ui'
import { useSiteStore } from '~/store/site'
import { usePricingStore } from '~/store/pricing'
import SmartDialog from "~/components/Layout/SmartDialog.vue";
import ro from "vue2-datepicker/locale/es/ro";
import RelayPlatformOnboardingBanner from '~/components/RelayPlatformOnboarding/RelayPlatformOnboardingBanner.vue'
import Button from "~/components/DataInput/Button.vue";
import { useRelayPlatformStore } from '~/store/relayPlatform'


export default {
    head() {
        return {
            title: 'SalesPop | Dashboard',
        }
    },
    components: {
        Button,
        BasicLayout,
        SmartDialog
    },
    data(){
        return {
            period: {
                current: {
                    from: this.$moment().startOf('month'),
                    to: this.$moment()
                },
            },
        }
    },
    computed: {
        ...mapState(useSiteStore, [
            'currentSite',
            'correctPlanName',
            'daysLeftOnAnnualPlan',
            'daysLeftOnFreePlan',
            'showAnnualPricingPlanBanner',
        ]),
        ...mapState(usePricingStore, ['limitReached', 'plan']),
        ...mapState(useUiStore, ['loadingPreflight']),
        ...mapState(
          useRelayPlatformStore,
          [
            'isRelayOnboardingStatusApprovedNotStarted',
            'isRelayOnboardingStatusUnapprovedOffered',
          ]
        ),
        ...mapWritableState(useUiStore, ['showRedirectToDashboardChangeModal']),
        daysRemaining(){
            return this.currentSite?.utils?.show_days_remaining_free_plan_banner
        },
    },
}
