
import { useRelayPlatformStore } from '~/store/relayPlatform'
import { mapState, mapActions } from 'pinia'

export default {
  data() {
    return {};
  },

  computed: {
    ...mapState(useRelayPlatformStore, ['childUrl', 'isRelayOnboardingStatusNotLive']),
  },

  methods: {
    ...mapActions(useRelayPlatformStore, ['getRelayPlatformUrlForConnection'])
  },

  async created() {
    if (this.isRelayOnboardingStatusNotLive) return

    await this.getRelayPlatformUrlForConnection('channels')
  },
};
