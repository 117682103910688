import { defineStore } from 'pinia'
import pickBy from 'lodash/pickBy'
import localeCodes from 'locale-codes'
import { useUiStore } from '~/store/ui'
import { useUserStore } from "~/store/user";

export const useSiteStore = defineStore({
    id: 'site',
    state(){
        return {
            loading: true,
            notifications: [],
            invoices: [],
            currentSite: {
                is_admin: false,
                proof_visible: null,
                reviewed_at: false,
                shopify_domain: '',
                vouch_testimonial_1_uploaded_at: null,
                billing_active: true,
                parsed_downgrade_pricing_plan_at: null,
                parsed_discount_until: null,
                session_quota_usage: 0,
                utils: {
                    show_customer_community_home_page: false,
                    show_review_modal: false,
                    show_consistent_cart_testimonial_modal: false,
                    show_fb_page_like_widget: false
                }
            },
            loadingAdditionalSessionsPurchase: false,
            discountCode: '',
            loadingApplyingDiscountCode: false,
            toolsInitialized: false,
            isChangingBrandingInProgress: false,
            planChangingInProgress: false,
        }
    },
    getters: {
        parsedLocale() {
            return localeCodes.getByTag(this.currentSite?.proof_locale) ?? {}
        },
        showFreePlanDaysLimitNotices() {
            return this.currentSite.utils.show_free_plan_days_limit_notices
        },
        daysLeftOnFreePlan() {
            return this.currentSite.utils.days_remaining_on_free_trial
        },
        daysLeftOnAnnualPlan() {
            return this.currentSite.utils.days_remaining_on_annual_plan
        },
        showAnnualPricingPlanBanner(){
            return this.daysLeftOnAnnualPlan > 0 && this.daysLeftOnAnnualPlan < 30
        },
        correctPlanName() {
            if (this.currentSite.utils.with_pricing_plan) {
                return this.currentSite.pricing_plan.name
            }
            if (this.currentSite.plan === 'paid') return 'Legacy Growth'
            return this.currentSite.plan
        },
        uniqueId() {
            return this.currentSite.unique_id
        },
        isOnUsageBasedPricing() {
            return this.currentSite.utils.new_or_old_pricing_tier === 'usage_based'
        },
        phoneSupportAvailable() {
            return this.currentSite.pricing_plan?.phone_support_available
        },
        popBrandingUnchangeable() {
            return !this.currentSite.pricing_plan?.pop_branding_changeable
        },
        shopifySubscriptionCappedAmount() {
            return parseFloat(this.currentSite.shopify_subscription_capped_amount).toFixed(2)
        },
    },
    actions: {
        initializeExternalTools() {
          if(this.toolsInitialized) return

          const { unique_id, site_owner_name, email, help_scout_beacon_signature, is_admin } = this.currentSite

          if (!is_admin) {
            window.Beacon('init', process.env.HELP_SCOUT_BEACON_SECURE_KEY)

            if (this.$hj) this.$hj('identify', unique_id)
          }

          if (help_scout_beacon_signature) {
            window.Beacon("identify", {
              name: site_owner_name,
              email: email,
              signature: help_scout_beacon_signature
            })
          }

          window.Beacon('navigate', '/ask/chat')

          this.toolsInitialized = true
        },

        async loadCurrentSite() {
            await this.$nuxt.$axios.$get(`sites/current`).then(response => {

                this.currentSite = response

                const parsedDate = (date) => { return new Date(date).toDateString() }

                if(this.currentSite.downgrade_pricing_plan_at) {
                    this.currentSite.parsed_downgrade_pricing_plan_at = parsedDate(this.currentSite.downgrade_pricing_plan_at)
                }

                if(this.currentSite.discount_until) {
                    this.currentSite.parsed_discount_until = parsedDate(this.currentSite.discount_until)
                }
            }).finally(() => {
                this.loading = false
            })
        },
        async siteAction(payload) {
            let cleanAction = {
                name: '',
            }
            if(typeof payload === 'object') {
                cleanAction = payload
            }

            if(typeof payload === 'string') {
                cleanAction.name = payload
            }

            try {
                await this.$nuxt.$axios.$post('site_actions/create', cleanAction)
                this.loadCurrentSite()
            } catch (e) {
                console.error(e)
            }
        },
        async saveSiteSettings() {
            if(this.loading) return

            this.loading = true
            try {
                const settings = pickBy(this.currentSite, (value, key) => {
                    // TODO: Not sending the plan, sets {plan: null}
                    return key.startsWith('proof_') || key === 'plan'
                })
                const currentSite = await this.$nuxt.$axios.$put(
                    'sites/current',
                    settings
                )
                this.currentSite = currentSite
                this.$nuxt.$toasts.add({
                    type: 'success',
                    title: 'Settings updated!',
                    subtitle: `Your settings were successfully updated`
                })
            } catch (e) {
                this.$nuxt.$toasts.add({
                    type: 'error',
                    title: 'Could not save the changes!',
                    subtitle:'Oops! Something went wrong and we could not save at this moment.',
                    e
                })
            }
            this.loading = false
        },
        async saveSitePlan({plan, preventLoad}) {
            try {
                const currentSite = await this.$nuxt.$axios.$put('sites/current', { plan })
                if(!preventLoad) this.currentSite = currentSite
                this.$nuxt.$track(plan === 'paid' ? 'subscribed' : 'unsubscribed')
                this.$nuxt.$toasts.add({
                    type: 'success',
                    title: 'Plan updated!',
                    subtitle: `Your plan: <b>${plan === 'paid' ? 'Pro' : 'Free'}</b>`
                })
            } catch (e) {
                this.$nuxt.$toasts.add({
                    type: 'error',
                    title: 'Could not save the changes!',
                    subtitle: 'Oops! Something went wrong and we could not save at this moment.',
                    e
                })
            }
        },
        async updateSitePricingPlan(pricingPlan) {
            this.planChangingInProgress = true

            try {
                let { shopifyHost } = useUserStore()
                const response = await this.$nuxt.$axios.$patch(
                    `${process.env.API_V1_URL}/shops/current/pricing_plan`,
                    { shop: { pricing_plan_id: pricingPlan.id, shopify_host: shopifyHost } }
                )

                const { data } = response

                if(data.redirect_url) {
                    window.top.location.href = data.redirect_url
                } else {
                    this.$nuxt.$toasts.add({
                      type: 'success',
                      title: 'Plan updated!',
                      subtitle: `Your plan: <b>${pricingPlan.name}</b>`
                    })

                    this.loadCurrentSite()

                    this.planChangingInProgress = false
                }

                return data
            } catch(error) {
                this.$nuxt.$toasts.add({
                    type: 'error',
                    title: 'Could not save the changes!',
                    subtitle: `${error.response.data.errors.join(".\n")}`,
                    bottomMessage: '',
                    error
                })

                this.planChangingInProgress = false
            }
        },
        setNewDevices(devices){
            const newDevices = {}
            useUiStore().popRules.popDevices.forEach(device => {
                newDevices[device.settingsKey] = devices.includes(device.text)
            })
            this.currentSite = {
                ...this.currentSite,
                ...newDevices
            }
        },
        async loadInvoices() {
            try {
                const invoices = await this.$nuxt.$axios.$get('invoices')
                this.invoices = invoices.invoices
            } catch (e) {
                console.error(e)
            }
        },
        async changePricingPlanAutoUpgrade(params) {
            this.planChangingInProgress = true

            try {
                await this.$nuxt.$axios.$patch(`${process.env.API_V1_URL}/shops/current/pricing_plan_auto_upgrade`,
                    { shop: params })

                this.$nuxt.$toasts.add({
                    type: 'success',
                    title: 'Changes saved!',
                    subtitle: 'Your changes were successfully saved!'
                })

            } catch (error) {
                this.$nuxt.$toasts.add({
                    type: 'error',
                    title: 'Could not save the changes!',
                    subtitle: error.response.data.errors.join(".\n"),
                    bottomMessage: '',
                    error
                })

                this.currentSite.pricing_plan_auto_upgrade_enabled = !this.currentSite.pricing_plan_auto_upgrade_enabled
            } finally {
                this.planChangingInProgress = false
            }
        },
        async reAuthSiteShop() {
            try {
                let { shopifyHost } = useUserStore()

                let response = await this.$nuxt.$axios.$post(`${process.env.API_V1_URL}/shops/current/re_auth`,
                  {
                    host: shopifyHost,
                  }
                )

                window.top.location.href = response.data.redirect_url
            } catch (error) {
              console.error(error)
            }
        },
        async patchBranding() {
          try{
            this.isChangingBrandingInProgress = true;

            await this.$nuxt.$axios.$patch(
              `${process.env.API_V1_URL}/shops/current/pop_branding`,
              { shop: { proof_show_powered_by: this.currentSite.proof_show_powered_by } }
            )

            this.$nuxt.$toasts.add({
              type: 'success',
              title: 'Changes saved!',
              subtitle: 'Your changes were successfully saved!'
            })

          } catch(error) {
            this.$nuxt.$toasts.add({
              type: 'error',
              title: 'Could not save the changes!',
              subtitle: error.response.data.errors.join(".\n"),
              bottomMessage: '',
              error
            })

            this.currentSite.proof_show_powered_by = !this.currentSite.proof_show_powered_by
          } finally {
            this.isChangingBrandingInProgress = false;
          }
        } ,
        async connectToJudgeMe(token, hide_unpublished_judgeme_reviews, hide_archived_judgeme_reviews) {
            try {
                await this.$nuxt.$axios.$patch(
                    `${process.env.API_V1_URL}/reviews/judgeme/connection`,
                    {
                      judge_me: {
                        token,
                        hide_unpublished_judgeme_reviews,
                        hide_archived_judgeme_reviews,
                      }
                    }
                )

                this.$nuxt.$toasts.add({
                    type: 'success',
                    title: 'Connected!',
                    subtitle: 'You have successfully connected to Judge.me. Reviews from Judge.me are currently importing. ' +
                              'Also note that Judge.me reviews you get in the future will automatically appear in Sales Pop.'
                })
            } catch (error) {
                this.$nuxt.$toasts.add({
                    type: 'error',
                    title: 'Could not connect to Judge.me!',
                    subtitle: error.response.data.errors.join(".\n"),
                    error
                })
            }
        },
        async purchaseAdditionalSessions(extra_sessions_limit) {
            this.loadingAdditionalSessionsPurchase = true

            try {
                let response = await this.$nuxt.$axios.$post(`${process.env.API_V1_URL}/sessions/purchase_extra`,
                    { extra_sessions_limit: extra_sessions_limit })

                this.currentSite.extra_sessions_limit = response.data.extra_sessions_limit

                this.$nuxt.$toasts.add({
                    type: 'success',
                    title: 'Changes saved!',
                    subtitle: 'You successfully purchased extra sessions.'
                })
            } catch (error) {
                this.$nuxt.$toasts.add({
                    type: 'error',
                    title: 'Could not save the changes!',
                    subtitle: 'Oops! Something went wrong and we could not save at this moment.',
                    error
                })
            }

            this.loadingAdditionalSessionsPurchase = false
        },
        async applyDiscountCode() {
            this.loadingApplyingDiscountCode = true

            try {
                await this.$nuxt.$axios.$post(`${process.env.API_V1_URL}/pricing_discounts/apply`,
                    { pricing_discount: { code: this.discountCode } })

                this.$nuxt.$toasts.add({
                    type: 'success',
                    title: 'Changes saved!',
                    subtitle: 'You successfully applied discount code'
                })
            } catch (error) {
                this.$nuxt.$toasts.add({
                    type: 'error',
                    title: 'Could not save the changes!',
                    subtitle: 'Oops! Something went wrong and we could not save at this moment.',
                    error
                })
            }

            this.loadingApplyingDiscountCode = false
        },
    }
})
