
import { mapState } from 'pinia'
import { useSiteStore } from '~/store/site'
import capitalize from 'lodash/capitalize'

export default {
    computed: {
        ...mapState(useSiteStore, ['currentSite']),
        pricingPlanName() {
            return this.currentSite.pricing_plan?.name
        },
        pricingPlanType() {
            return capitalize(this.currentSite.pricing_plan?.type || "")
        },
        pricingPlanDowngradeToName() {
            return this.currentSite.pricing_plan_downgrade_to?.name
        },
        pricingPlanDowngradeToType() {
          return capitalize(this.currentSite.pricing_plan_downgrade_to?.type || "")
        },
    }
}
