
import { mapActions, mapState } from 'pinia'
import { useRelayPlatformStore } from '~/store/relayPlatform'
import { useSiteStore } from '~/store/site'

export default {
  data() {
    return {
      redirectAccessScopesUrl: '',
    };
  },

  computed: {
    ...mapState(useRelayPlatformStore, ['relayPlatformStaticPagesUrl', 'relayPlatformOnboardingStatus']),
    ...mapState(useSiteStore, ['currentSite']),

    selectSrcUrl () {
      const url = this.relayPlatformStaticPagesUrl

      const urlA = url + '?variant=A'
      const urlB = url + '?variant=B'
      const urlC = url + '?variant=C'

      const status = this.relayPlatformOnboardingStatus

      const urls = {
        'approved_not_started': urlA,
        'unapproved_offered': urlB,
        'approved_setup_pending': urlC,
        'unapproved_registered': urlC,
      }

      return urls[status]
    }
  },
  mounted() {
    window.addEventListener('message', this.handleIframeUpdateAccessScopes)
    window.addEventListener('message', this.handleIFrameUnapprovedOfferedButton)
  },

  beforeDestroy() {
    window.removeEventListener('message', this.handleIframeUpdateAccessScopes)
    window.removeEventListener('message', this.handleIFrameUnapprovedOfferedButton)
  },

  methods: {
    ...mapActions(useRelayPlatformStore, ['getRelayPlatformUrlForConnection', 'updateRelayOnboardingUserStatus']),
    ...mapActions(useSiteStore, ['reAuthSiteShop']),

    handleIframeUpdateAccessScopes(event) {
      if(event.data.type == 'RelayPlatformModalButton') {
        if (this.currentSite.force_relay_platform_onboarding_auth_scopes_reauth === true) {
          this.reAuthSiteShop()
        } else {
          this.updateRelayOnboardingUserStatus('approved_setup_pending')
        }
      }
    },

    handleIFrameUnapprovedOfferedButton(event) {
      if (event.data.type == 'RelayPlatformUnapprovedOfferedButton') {
        this.updateRelayOnboardingUserStatus('unapproved_registered');
      }
    },
  },
};
