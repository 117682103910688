
import BasicLayout from '~/layouts/basic'
import availableUpsellsList from '~/components/Upsells/availableUpsellsList'
import { mapWritableState, mapActions, mapState } from 'pinia'
import { useAddToCartStore } from '~/store/upsells/add_to_cart'
import { useUpsellsStore } from '~/store/upsells'
import { usePricingStore } from "~/store/pricing"
import { useSiteStore } from '~/store/site';
import { useBaseOnboardingStore } from "~/store/onboardings/baseOnboarding";
import { useNewCustomerOnboardingStore } from "~/store/onboardings/newCustomer";

export default {
  components: {
    BasicLayout,
    availableUpsellsList
  },
  data: () => ({
    screen: 'desktop',
    upgradeModalOpen: false,
    intendedProIntegration: null,
    forceReauthOpen: false,
  }),
  async mounted() {
    await this.loadAddToCart()
      if (this.onboarding?.getCurrentStep()?.id === 'upsells_edit_sidebar_button') {
          this.showOnboardingStep('upsells_edit_rules_and_design_button')
      }
  },
  methods: {
    ...mapActions(useUpsellsStore, [
      'loadUpsellsList'
    ]),
    ...mapActions(useAddToCartStore, ['loadAddToCart', 'saveAddToCart']),
    ...mapActions(useSiteStore, [
      'reAuthSiteShop'
    ]),
    ...mapActions(useBaseOnboardingStore, ['showOnboardingStep']),
    async handleEnableToggle(key){
      if (!this.$isPro()) {
        this.upgradeModalOpen = true
        return
      }

      if (this.shouldForceReAuth()) {
        this.forceReauthOpen = true
        return
      }

      this.addToCart.enabled = !this.addToCart.enabled
      await this.saveAddToCart()
      await this.loadUpsellsList()
    },
    handleEditClick() {
      if (!this.$isPro()) {
        this.upgradeModalOpen = true
        return
      }

      this.$router.push('/upsells/rules-and-settings/add-to-cart/discounts')
    },
    handleAcceptReAuth() {
      this.reAuthSiteShop()
    },
    handleDismissReAuth() {
      this.forceReauthOpen = false
    },
    shouldForceReAuth() {
      return this.currentSite.force_upsells_and_pops_auth_scopes_reauth && !this.addToCart.enabled
    },
  },
  computed: {
    ...mapState(usePricingStore, ['plan']),
    ...mapWritableState(useAddToCartStore, ['addToCart', 'upsellAddToCartExampleProps', 'loading']),
    ...mapState(useSiteStore, ['currentSite']),
    ...mapState(useNewCustomerOnboardingStore, ['onboarding']),
  }
}
