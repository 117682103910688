import { render, staticRenderFns } from "./ProgressCard.vue?vue&type=template&id=176d21b3&scoped=true&lang=pug"
import script from "./ProgressCard.vue?vue&type=script&lang=js"
export * from "./ProgressCard.vue?vue&type=script&lang=js"
import style0 from "./ProgressCard.vue?vue&type=style&index=0&id=176d21b3&prod&lang=postcss&scoped=true"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "176d21b3",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {Button: require('/opt/build/repo/components/DataInput/Button.vue').default})
