import { render, staticRenderFns } from "./Sidebar.vue?vue&type=template&id=62671827&scoped=true&lang=pug"
import script from "./Sidebar.vue?vue&type=script&lang=js"
export * from "./Sidebar.vue?vue&type=script&lang=js"
import style0 from "./Sidebar.vue?vue&type=style&index=0&id=62671827&prod&lang=postcss&scoped=true"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "62671827",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {SidebarButton: require('/opt/build/repo/components/Layout/SidebarButton.vue').default,Tag: require('/opt/build/repo/components/DataDisplay/Tag.vue').default,EmptyMenuItem: require('/opt/build/repo/components/EmptyState/EmptyMenuItem.vue').default,PlanLimits: require('/opt/build/repo/components/business/PlanLimits.vue').default})
